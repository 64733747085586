/* src/app/views/layout/navbar/navbar.component.scss */
.switch_btn {
  font-family: "DroidKufi" !important;
}
.switch_btn:lang(ar) {
  font-family: "Poppins", sans-serif !important;
}
.nav-link:hover {
  cursor: pointer;
}
/*# sourceMappingURL=navbar.component.css.map */
