import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { RoleGuardService } from './core/guard/role-guard.service';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';


const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        //  canActivate: [AuthGuard, RoleGuardService],
        // data: {
        //   expectedRole: "sub_admin",
        // },
      },
      // {
      //   path: 'charts-graphs',
      //   loadChildren: () => import('./views/pages/charts-graphs/charts-graphs.module').then(m => m.ChartsGraphsModule)
      // },
      {
        path: 'service',
        loadChildren: () => import('./views/pages/services/services.module').then(m => m.ServicesModule)
      },
      {
        path: 'time',
        loadChildren: () => import('./views/pages/time/time.module').then(m => m.TimeModule)
      },
      {
        path: 'servicerule',
        loadChildren: () => import('./views/pages/servicerule/servicerule.module').then(m => m.ServiceruleModule)
      },
      {
        path: 'terms',
        loadChildren: () => import('./views/pages/termcondition/termcondition.module').then(m => m.TermconditionModule)
      },
      {
        path: 'aboutus',
        loadChildren: () => import('./views/pages/aboutus/aboutus.module').then(m => m.AboutusModule)
      },
      {
        path: 'privacypolicy',
        loadChildren: () => import('./views/pages/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule)
      },
      {
        path: 'helps',
        loadChildren: () => import('./views/pages/helps/helps.module').then(m => m.HelpsModule)
      },
      {
        path: 'product',
        loadChildren: () => import('./views/pages/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'carbrand',
        loadChildren: () => import('./views/pages/carbarnds/carbarnds.module').then(m => m.CarbarndsModule)
      },
      {
        path: 'carcolor',
        loadChildren: () => import('./views/pages/carcolor/carcolor.module').then(m => m.CarcolorModule)
      },
      {
        path: 'carmodal',
        loadChildren: () => import('./views/pages/carmodal/carmodal.module').then(m => m.CarmodalModule)
      },
       {
        path: 'coupons',
        loadChildren: () => import('./views/pages/coupon/coupon.module').then(m => m.CouponModule)
      },
      {
        path: 'coupons-view/:id',
        loadChildren: () => import('./views/pages/coupons-view/coupons-view.module').then(m => m.CouponsViewModule)
      },
      {
         path: 'commission',
        loadChildren: () => import('./views/pages/commission/commission.module').then(m => m.CommissionModule)
      },
      {
        path: 'zones',
        loadChildren: () => import('./views/pages/zone/zone.module').then(m => m.ZoneModule)
      },
      {
        path: 'agents',
        loadChildren: () => import('./views/pages/agents/agents.module').then(m => m.AgentsModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./views/pages/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: "clientorders/:id",
        loadChildren: () => import('./views/pages/client-ordersview/client-ordersview.module').then(m => m.ClientOrdersviewModule)
      },
      {
        path: "clientWalletTransactions/:id",
        loadChildren: () => import('./views/pages/client-wallet/client-wallet.module').then(m => m.ClientWalletModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/pages/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'bikerreport',
        loadChildren: () => import('./views/pages/bikerreport/bikerreport.module').then(m => m.BikerreportModule)
      },
      {
        path: 'completeOrders',
        loadChildren: () => import('./views/pages/completeorders/completeorders.module').then(m => m.CompleteordersModule)
      },
      {
        path: 'salesReport',
        loadChildren: () => import('./views/pages/salesreport/salesreport.module').then(m => m.SalesreportModule)
      },
      {
        path: 'customerreport',
        loadChildren: () => import('./views/pages/customerreport/customerreport.module').then(m => m.CustomerreportModule)
      },
      {
        path: 'carDetails',
        loadChildren: () => import('./views/pages/cardetails/cardetails.module').then(m => m.CardetailsModule)
      },
      {
        path: 'assignment',
        loadChildren: () => import('./views/pages/assignment/assignment.module').then(m => m.AssignmentModule)
      },
      {
        path: 'agentview',
        loadChildren: () => import('./views/pages/agentsview/agentsview.module').then(m => m.AgentsviewModule)
      },
      {
        path: "agentview/:id",
        loadChildren: () => import("./views/pages/agentsview/agentsview.module").then((m) => m.AgentsviewModule),
      },
      {
        path: "agentCommissions/:id",
        loadChildren: () => import("./views/pages/agent-commission/agent-commission.module").then((m) => m.AgentCommissionModule),
      },
      {
        path: 'orderview',
        loadChildren: () => import('./views/pages/ordersview/ordersview.module').then(m => m.OrdersviewModule)
      },
      {
        path: 'orderview/:id',
        loadChildren: () => import('./views/pages/ordersview/ordersview.module').then(m => m.OrdersviewModule)
      },
      {
        path: 'overall_orders',
        loadChildren: () => import('./views/pages/overall-orders/overall-orders.module').then(m => m.OverallOrdersModule)
      },
      {
        path: 'cancelled_orders',
        loadChildren: () => import('./views/pages/cancelled-orders/cancelled-orders.module').then(m => m.CancelledOrdersModule)
      },
      {
        path: 'biker_cancel_requests',
        loadChildren: () => import('./views/pages/biker-cancel-requests/biker-cancel-requests.module').then(m => m.BikerCancelRequestsModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./views/pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'nationality',
        loadChildren: () => import('./views/pages/nationality/nationality.module').then(m => m.NationalityModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'notify',
        loadChildren: () => import('./views/pages/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'washerhelp',
        loadChildren: () => import('./views/pages/washerhelp/washerhelp.module').then(m => m.WasherhelpModule)
      },
      {
        path: 'subscription',
        loadChildren: () => import('./views/pages/subscription/subscription.module').then(m => m.SubscriptionModule)
      },
      {
        path: 'subscription-details-view/:id',
        loadChildren: () => import('./views/pages/subscription-details-view/subscription-details-view.module').then(m => m.SubscriptionDetailsViewModule)
      },
      {
        path: 'admin-user',
        loadChildren: () => import('./views/pages/admin-user/admin-user.module').then(m => m.AdminUserModule)
      },
      {
        path: 'admin-roles',
        loadChildren: () => import('./views/pages/admin-user/role/role.module').then(m => m.RoleModule)
      },
      {
        path: 'corporate-customer',
        loadChildren: () => import('./views/pages/admin-user/corporate-customer/corporate-customer.module').then(m => m.CorporateCustomerModule)
      },
      {
        path: 'notificationview',
        loadChildren: () => import('./views/pages/notificationview/notificationview.module').then(m => m.NotificationviewModule)
      },
      {
        path: 'notificationcontent',
        loadChildren: () => import('./views/pages/notifiycontent/notifiycontent.module').then(m => m.NotifiycontentModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('./views/pages/banners/banners.module').then(m => m.BannersModule)
      },
         {
        path: 'city',
        loadChildren: () => import('./views/pages/city/city.module').then(m => m.CityModule)
      },
      {
        path: 'country',
        loadChildren: () => import('./views/pages/country/country.module').then(m => m.CountryModule)
      },
      {
        path: 'vendor',
        loadChildren: () => import('./views/pages/vendor/vendor.module').then(m => m.VendorModule)
      },
      {
        path: 'storage',
        loadChildren: () => import('./views/pages/storage/storage.module').then(m => m.StorageModule)
      },
      {
        path: 'storage/:id',
        loadChildren: () => import('./views/pages/storage-voucher/storage-voucher.module').then(m => m.StorageVoucherModule)
      },
      {
        path: 'storageVoucher',
        loadChildren: () => import('./views/pages/storage-voucher/storage-voucher.module').then(m => m.StorageVoucherModule)
      },
      {
        path: 'storageVoucherOut',
        loadChildren: () => import('./views/pages/storagevoucherout/storagevoucherout.module').then(m => m.StoragevoucheroutModule)
      },
      {
        path: 'storageVoucherItemsReport',
        loadChildren: () => import('./views/pages/storage-voucher-items-report/storage-voucher-items-report.module').then(m => m.StorageVoucherItemsReportModule)
      },
      {
        path: 'storageVoucherReport',
        loadChildren: () => import('./views/pages/storage-voucher-report/storage-voucher-report.module').then(m => m.StorageVoucherReportModule)
      },
      {
        path: 'transferStorage',
        loadChildren: () => import('./views/pages/transfer-voucher/transfer-voucher.module').then(m => m.TransferVoucherModule)
      },
      {
        path: 'purchase',
        loadChildren: () => import('./views/pages/purchase-report/purchase-report.module').then(m => m.PurchaseReportModule)
      },
      {
        path: 'transfer',
        loadChildren: () => import('./views/pages/transfer-report/transfer-report.module').then(m => m.TransferReportModule)
      },
      {
        path: 'stocks',
        loadChildren: () => import('./views/pages/stocks-avl-report/stocks-avl-report.module').then(m => m.StocksAvlReportModule)
      },
      {
        path: 'bikerDateReport',
        loadChildren: () => import('./views/pages/biker-date-report/biker-date-report.module').then(m => m.BikerDateReportModule)
      },
      {
        path: 'auditAction',
        loadChildren: () => import('./views/pages/audit-action-report/audit-action-report.module').then(m => m.AuditActionReportModule)
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./views/pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
      },
      {
        path: 'biker-reports',
        loadChildren: () => import('./views/pages/biker-reports/biker-reports.module').then(m => m.BikerReportsModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },

  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },

  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
