import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
// import { HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from "./shared/auth.interceptor";
// import { TabelComponent } from './views/pages/tabel/tabel.component';
import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
// import { TableComponent } from './views/pages/table/table.component';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

const config: SocketIoConfig = {
  url: "https://ghasslapp.com",
  options: {
    transports: ['websocket']
  }
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({ declarations: [
        AppComponent,
        ErrorPageComponent,
        // TableComponent,
        // TabelComponent,
    ],
    exports: [
        MatFormFieldModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        CommonModule,
        ToastrModule.forRoot(),
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })], providers: [
        CurrencyPipe, DecimalPipe, PercentPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        AuthGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}