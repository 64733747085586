<nav class="navbar">
  <a class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <ul class="navbar-nav">
      <li class="nav-item" ngbDropdown *ngIf="lang == 'en'">
        <a class="nav-link switch_btn" ngbDropdownToggle id="languageDropdown" role="button" (click)="switchLang('ar')">
          <i class="flag-icon flag-icon-us mt-1" title="us"></i> <span
            class="font-weight-medium ml-1 mr-1 d-none d-md-inline-block">العربية</span>
        </a>
      </li>
      <li class="nav-item" ngbDropdown *ngIf="lang == 'ar'">
        <a class="nav-link switch_btn" ngbDropdownToggle id="languageDropdown" role="button" (click)="switchLang('en')">
          <i class="flag-icon flag-icon-us mt-1" title="us"></i> <span
            class="font-weight-medium ml-1 mr-1 d-none d-md-inline-block">English</span>
        </a>
      </li>
      <li class="nav-item nav-notifications" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator">
            <div class="circle"></div>
          </div>
        </a>
        <div ngbDropdownMenu aria-labelledby="notificationDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">Notifications</p>
            <!-- <a href="" (click)="false" class="text-muted">Clear all</a> -->
          </div>
          <div class="dropdown-body" style="height: 300px;overflow-y: scroll;">
            <a href="" (click)="false" class="dropdown-item" *ngFor="let data of notification">
              <div class="icon">
                <i class="feather icon-user-plus"></i>
              </div>
              <div class="content">
                <p>{{data.message}}</p>
                <p class="sub-text text-muted">{{data.sendDate | date:'medium'}}</p>
              </div>
            </a>
          </div>
          <div class="dropdown-footer d-flex align-items-center justify-content-center">
            <a routerLink="/notificationview">View all</a>
          </div>
        </div>
      </li>
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img src="https://via.placeholder.com/30x30" alt="profile">
        </a>
        <div ngbDropdownMenu aria-labelledby="profileDropdown">
          <div class="dropdown-header d-flex flex-column align-items-center">
            <div class="info text-center">
              <p class="name font-weight-bold mb-0">{{adminName}}</p>
              <p class="email text-muted mb-3">{{role}}</p>
            </div>
          </div>
          <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
              <li class="nav-item">
                <a href="javascript: void(0);" (click)="onLogout()" class="nav-link">
                  <i class="feather icon-log-out"></i>
                  <span>Log Out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
<!-- <ng-template #password role="document" let-modal="close">
  <div class="modal-header">
    <button type="close" class="btn close" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form [formGroup]="validationform">
        <div class="form-group">
          <label for="name">Change Password</label>
          <input type="text" class="form-control" placeholder="Enter brand" formControlName="brands">
        </div>
      </form>
      <div class="text-right">
        <button type="submit" class="btn btn-own" (click)="saveData()">Save</button>
        <button type="button" class="btn btn-own-cancel ml-1" (click)="modal('close click')">Cancel</button>
      </div>
    </div>
  </div>
</ng-template> -->