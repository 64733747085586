/* src/app/views/layout/sidebar/sidebar.component.scss */
.nav-link {
  cursor: pointer;
}
.sub-menu {
  transition: max-height 0.3s ease-in-out;
}
.sidebar-body {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
/*# sourceMappingURL=sidebar.component.css.map */
