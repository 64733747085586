import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { AdminActionsI } from './models';
// const AUTH_API = 'http://192.168.1.25:8080/api/v1/admin';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
  })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  endpoint = environment.baseUrl;
  newEndpoint = environment.newbaseUrl;
  s3endpoint = environment.s3Url;
  headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Credentials', 'true');
  constructor(private http: HttpClient, private router: Router,) { }
  adminid = JSON.parse(sessionStorage.getItem("adminId")) || 0;
  //s3upload
  //s3upload
  s3upload(user: any): Observable<any> {
    const apis3 = `${this.s3endpoint}/users/putImageToS3`;
    return this.http.post(apis3, user).pipe(catchError(this.handleError));
  }

  s3uploadForAdmin(user: any): Observable<any> {
    const apis3 = `${this.s3endpoint}/admin/putImageToS3`;
    return this.http.post(apis3, user).pipe(catchError(this.handleError));
  }

  // Sign-in
  signIn(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/loginAdmin`, user);
  }

  //Service

  Addservice(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/addNewService`, user);
  }

  getservice(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllServices`,
      { params: param1 });
  }

  getStorage(data: any): Observable<any> {
    const params = {
      lang: 'en',
      'adminId': this.adminid,
      ...(data?.storageId && { storageId: data?.storageId })
    }
    return this.http.get<any>(`${this.endpoint}/get-storage`,
    { params: params });
  }

  getCity(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/city`);
  }

  addCity(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/city`, { ...user, adminId: this.adminid });
  }

  updateCity(data) {
    return this.http
      .put<any>(`${this.endpoint}/city`, { ...data, adminId: this.adminid });
  }

  deleteCity(cityId: any) {
    return this.http
      .delete<any>(`${this.endpoint}/city/${cityId}`, { params: { adminId: this.adminid } });
  }

  getCountryData(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/country`);
  }

  addCountry(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/country`, { ...user, adminId: this.adminid });
  }

  updateCountry(data) {
    return this.http
      .put<any>(`${this.endpoint}/country`, { ...data, adminId: this.adminid });
  }

  deleteCountry(countryId: any) {
    return this.http
      .delete<any>(`${this.endpoint}/country/${countryId}`, { params: { adminId: this.adminid } });
  }

  getVendorData(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/vendor`);
  }

  addVendor(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/vendor`, { ...user, adminId: this.adminid });
  }

  updateVendor(user: any) {
    return this.http
      .put<any>(`${this.endpoint}/vendor`, { ...user, adminId: this.adminid });
  }

  deleteVendor(countryId: any) {
    return this.http
      .delete<any>(`${this.endpoint}/vendor/${countryId}`, { params: { adminId: this.adminid } });
  }

  getnotificationcontent(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllPushNotificationContent`,
      { params: param1 });
  }

  Deleteservice(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteService`, user);
  }

  Viewservice(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editServiceVisible`, user);
  }

  Editservice(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editService`, user);
  }

  getserviceId(value): Observable<any> {
    console.log("ad", value)
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('serviceId', value);
    return this.http.get<any>(`${this.endpoint}/getService`,
      { params: param1 });
  }



  //Time Slot

  Addtimeslot(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/addNewTimeSlot`, user);
  }

  gettimeslot(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllTimeslots`,
      { params: param1 });
  }

  Deletetime(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteTimeSlots`, user);
  }

  ViewTime(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editTimeSlotVisible`, user);
  }


  // Service Rules

  AddServicerule(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addServiceRules`, user);
  }

  getServicerule(client): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('forUser', client)
    return this.http.get<any>(`${this.endpoint}/getServiceRules`,
      { params: param1 });
  }

  //Terms&condition

  addTerms(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addTermsAndConditons`, user);
  }

  getTerms(client): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('forUser', client)
    return this.http.get<any>(`${this.endpoint}/getTermsAndConditons`,
      { params: param1 });
  }

  //About us

  addAboutus(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addAboutus`, user);
  }

  getAboutus(client): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('forUser', client)
    return this.http.get<any>(`${this.endpoint}/getAboutus`,
      { params: param1 });
  }

  //Privacy Policy

  addPrivacy(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addPrivacyPolicy`, user);
  }

  getPrivacy(client): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('forUser', client)
    return this.http.get<any>(`${this.endpoint}/getPrivacyPolicy`,
      { params: param1 });
  }

  //helps
  addHelp(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addHelp`, user);
  }

  getHelp(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getHelp`,
      { params: param1 });
  }

  editHelp(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editHelp`, user);
  }

  getHelpwasher(object:any): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('startDate', object.startDate)
      .set('endDate', object.endDate)
    return this.http.get<any>(`${this.endpoint}/getAgentProductHelp`,
      { params: param1 });
  }


  Deletehelp(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteHelp`, user);
  }

  Viewhelp(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editHelpStatus`, user);
  }

  addHelpresolved(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/productHelpStatusUpdate`, user);
  }

  //faq
  addFaq(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/postFAQ`, user);
  }

  getFaq(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllFAQ`,
      { params: param1 });
  }

  editfaq(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editFAQ`, user);
  }

  editnotifycontent(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editPushnotificationContent`, user);
  }

  DeleteFaq(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteFAQ`, user);
  }

  ViewFaq(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editFAQ`, user);

  }
  //Products
  addProduct(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addProduct`, user);
  }

  Editproduct(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/editProduct`, user);
  }


  getProduct(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/listProduct`,
      { params: param1 });
  }
  DeleteProduct(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteProduct`, user);
  }

  visibleProduct(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/changeStatus`, user);
  }

  //Carbrands

  addcarBrand(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/postCarBrand`, user);
  }

  editCarBrandService(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/editCarBrand`, user);
  }

  getcarbrand(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getCarBrandslist`,
      { params: param1 });
  }

  DeleteCarbrand(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteCarBrand`, user);
  }

  //Carcolor
  addcarColor(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addCarColors`, user);
  }

  getcolor(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getCarColors`,
      { params: param1 });
  }

  DeletecarColor(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteCarColors`, user);
  }

  ViewCarcolor(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/changeStatus`, user);
  }

  editcolor(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editCarColors`, user);
  }

  //CarModal
  addcarModal(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/postCarModel`, user);
  }

  getModal(value): Observable<any> {
    console.log(value)
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('brandId', value);

    return this.http.get<any>(`${this.endpoint}/getCarModel`,
      { params: param1 });
  }

  DeleteCarModal(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteCarModel`, user);
  }

  ViewCarModal(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editCarModel`, user);
  }

  //Coupon

  addCoupon(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addNewCoupon`, user);
  }

  getCoupon(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getAllCoupon`,
      { params: param1 });
  }

  DeleteCoupon(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteOneCoupon`, user);
  }

  ViewCoupon(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/visibleStatusUpdate`, user);
  }
  editCoupon(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/editCoupon`, user);
  }

  //commission

  addCommission(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/postCommissions`, user);
  }

  editcommission(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editCommissions`, user);
  }

  getCommission(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllCommissionsList`,
      { params: param1 });
  }

  DeleteCommission(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteCommissions`, user);
  }

  getCommissionbiker(id:any): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('agentId', id)
    return this.http.get<any>(`${this.endpoint}/getAgentCommission`,
      { params: param1 });
  }

  updatecommisoonbiker(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/postAgentCommissionAmount`, user);
  }

  //notification
  addNotification(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addPushnotificationContent`, user);
  }
  getNotification(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getAllPushNotificationContent`,
      { params: param1 });
  }

  editNotification(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editPushnotificationContent`, user);
  }

  pushnotification(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/pushNotificationFirebase`, user);
  }
  pushsms(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/pushNotificationSms`, user);
  }
  getadminotifiaction(data: any): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/getAdminNotifications`,
      { params: { lang: 'en', 'adminId': this.adminid, ...data } });
  }
  updatenotification(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/updateNotificationStatus`, user);
  }

  //dashboard

  getDashboard(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/dashboradReport`,
      { params: param1 });
  }

  listdashboard(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/inboxDashboard`,
      { params: param1 });
  }


  graphdashboard(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('startDate', '10/04/2022')
      .set('endDate', '15/04/2022')

    return this.http.get<any>(`${this.endpoint}/inGraphDashboard`,
      { params: param1 });
  }

  graphdashboardyearly(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/inGraphYearlyDashboard`,
      { params: param1 });
  }

  // subscription
  addsubscription(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addSubscriptionPackage`, user);
  }

  addStorage(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/update-storage`, { ...user , adminId : this.adminid });
  }

  getSubscription(params:any): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('searchKey',params?.searchKey)
      .set('status',params?.status)

    return this.http.get<any>(`${this.endpoint}/getSubscriptionPackage`,
      { params: param1 });
  }

  DeleteSub(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteSubscriptionPackage`, user);
  }

  editsubscription(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editSubscriptionPackage`, user);
  }

  ViewSub(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editPackageVisible`, user);
  }
  //orders
  getORDERS(object:any): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('startDate', object.startDate)
      .set('endDate', object.endDate)
      .set('limit', object.limit)
      .set('searchKey', object.searchKey)
      .set('paymentStatus', object.paymentStatus)
      .set('orderStartDate', object.orderStartDate)
      .set('orderEndDate', object.orderEndDate)
    if (object?.orderStartDate) {
      param1.append('orderStartDate', object.orderStartDate)
    }
    if (object?.orderEndDate) {
      param1.append('orderEndDate', object.orderEndDate);
    }
    return this.http.get<any>(`${this.endpoint}/getUnAssignedOrders`,
      { params: param1 });
  }
  getagentfororder(value): Observable<any> {
    console.log("api", value)
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('timeSlotId', '20')
      .set('locationId', value.locationId._id);

    return this.http.get<any>(`${this.endpoint}/getAllAvailableAgnet`,
      { params: param1 });
  }
  getagentforspecial(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllAgnetforSpecialAssignment`,
      { params: param1 });
  }

  getAgentforManualAssignement(orderId,timeId): Observable<any> {
    const param1 = new HttpParams()
      .set('orderId', orderId)
      .set('timeslotId', timeId)
    return this.http.get<any>(`${this.newEndpoint}/getRaidersList`,
      { params: param1 });
  }

  getORDERSbyid(id): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('orderId', id)
    return this.http.get<any>(`${this.endpoint}/getOrder`,
      { params: param1 });
  }
  getbikerbyid(id): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('agentId', id)
    return this.http.get<any>(`${this.endpoint}/getAgentProfileById`,
      { params: param1 });
  }
  addagentorder(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/makeAssignmentManual`, user);
  }

  makeReassignmentOrder(user: any) {
    return this.http
      .post<any>(`${this.newEndpoint}/reAssignmentManual`, user);
  }

  //Zone
  addZone(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addNewZone`, user);
  }


  getZone(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getAllZones`,
      { params: param1 });
  }

  getActiveZone(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getActiveZones`,
      { params: param1 });
  }
  
  editZone(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/zoneStatusUpdate`, user);
  }

  getzonebyId() {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('zoneId', '3');
    return this.http
      .get<any>(`${this.endpoint}/getZoneById`, { params: param1 });
  }

  DeleteZone(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteZone`, user);
  }



  //Washing Agent

  getWashingagent(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/listAgents`,
      { params: param1 });
  }

  getWashingagentV1(searchKey:any): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set("searchKey",searchKey)

    return this.http.get<any>(`${this.endpoint}/listAgentsV1`,
      { params: param1 });
  }

  getAgentStatus(value): Observable<any> {
    console.log("api", value)
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('agentId', value);

    return this.http.get<any>(`${this.endpoint}/getAgentOnlineHistory`,
      { params: param1 });
  }

  Approveagent(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/assignZoneToAgent`, user);
  }
  UpdateAgent(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/updateActiveStatus`, user);
  }

  getagenttransitation(id:any): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('agentId', id);

    return this.http.get<any>(`${this.endpoint}/getAgentCommissionTransaction`,
      { params: param1 });
  }

  //customer reports
  getCustomerReport(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getCustomerReports`,
      { params: param1 });
  }

  //Biker Report
  getBikerReport(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getBikersReport`,
      { params: param1 });

  }

  //Assignment
  getAssignment(object:any): Observable<any> {

    let param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('startDate', object.startDate)
      .set('endDate', object.endDate)
      .set('limit', object.limit)
      .set('searchKey', object.searchKey)
      .set('paymentStatus', object.paymentStatus)
      .set('orderStatus', object.orderStatus)
      .set('orderStartDate', object.orderStartDate)
      .set('orderEndDate', object.orderEndDate);
      if (object?.statuses?.length) {
        object.statuses.forEach(element => {
          param1 = param1.append('statuses[]', element);
        });
      }
    
    return this.http.get<any>(`${this.endpoint}/getAllAssignments`,
      { params: param1 , headers : { data : 'GG' } });
  }

  getOrder(object:any): Observable<any> {
    let params = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('startDate', object.startDate)
      .set('endDate', object.endDate)
      .set('limit', object.limit)
      .set('searchKey', object.searchKey)
      .set('paymentStatus', object.paymentStatus)
      .set('orderStatus', object.orderStatus)
      .set('orderStartDate', object.orderStartDate)
      .set('orderEndDate', object.orderEndDate);
    if (object?.statuses?.length) {
      object.statuses.forEach(element => {
        params = params.append('statuses[]', element);
      });
    }
    return this.http.get<any>(`${this.endpoint}/getAllOrders`, 
      { params: params });
  }

  getStoragevoucherReport(object: any): Observable<any>{
    return this.http.post<any>(`${this.endpoint}/storage-voucher-report`,
    object);
  }
  //Client
  getClient(searchKey?:any, isBlocked?:any, pageSize?:any): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('searchKey', searchKey)
      .set('status', isBlocked)
      .set('limit', pageSize)

    return this.http.get<any>(`${this.endpoint}/listUsers`,
      { params: param1 });
  }

  getClientV1(searchKey?:any, isBlocked?:any, pageSize?:any): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('searchKey', searchKey)
      // .set('status', isBlocked)
      // .set('limit', pageSize)

    return this.http.get<any>(`${this.endpoint}/listUsersV1`,
      { params: param1 });
  }

  UpdateClient(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/changeBlockStatus`, user);
  }

  updateCustomer(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/update-user`, user);
  }

  getToken() {
    return sessionStorage.getItem('access_token');
  }
  // login(username: string, password: string): Observable<any> {
  //   return this.http.post(AUTH_API + 'signin', {
  //     username,
  //     password
  //   }, httpOptions);
  // }



  doLogout(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/logoutAdmin`, user)
  }

  // register(username: string, email: string, password: string): Observable<any> {
  //   return this.http.post(AUTH_API + 'signup', {
  //     username,
  //     email,
  //     password
  //   }, httpOptions);
  // }

  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
      if (error.status === 401 || error.status === 403) {
        // this.injector.get(UserService).purgeAuth();
        // this.injector.get(ToasterService).showError(`Unauthorized`, errorMsg);
        // this.injector.get(Router).navigateByUrl(`/login`);
        this.router.navigate(['/login']);
        console.log('errorthrows');
      }

      console.log('error', msg);
    }
    return throwError(msg);
  }

  getSetting(): Observable<any> {

    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getAllSettings`,
      { params: param1 });
  }

  updateSetting(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/editSettingVariable`, user);
  }

  getCustomerCars(): Observable<any> {

    const param1 = new HttpParams()
      .set("userId", JSON.parse(localStorage.getItem("userId")!))
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getCarsByUserId`,
      { params: param1 });
  }

  getCompleteOrders(object: any): Observable<any> {

    const param1 = new HttpParams()
      .set("agentId", object.agentId)
      .set("dateFrom", object.dateFrom)
      .set("dateTo", object.dateTo)
      .set("key", object.key)
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/overAllBikerDeatils`,
      { params: param1 });
  }
  getorderbydate(object: any): Observable<any> {

    const param1 = new HttpParams()
      // .set("agentId", object.agentId)
      .set("startDate", object.dateFrom)
      .set("endDate", object.dateTo)
      // .set("key",object.key)
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/getAllOrders`,
      { params: param1 });
  }
  getbikerbydate(object: any): Observable<any> {

    const param1 = new HttpParams()
      .set("agentId", object.agentId)
      .set("dateFrom", object.dateFrom)
      .set("dateTo", object.dateTo)
      .set("key", object.key)
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/overAllBikerDeatils`,
      { params: param1 });
  }

  //Orders
  getOrders(object: any): Observable<any> {

    const param1 = new HttpParams()
      .set("agentId", object.agentId)
      .set("dateFrom", object.dateFrom)
      .set("dateTo", object.dateTo)
      .set("key", object.key)
      .set('lang', 'en')
      .set('adminId', this.adminid)

    return this.http.get<any>(`${this.endpoint}/overAllBikerDeatils`,
      { params: param1 });
  }

  getadminUser(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllAdminUser`,
      { params: param1 });
  }
  getadminRoles(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getCreatedAdminRole`,
      { params: param1 });
  }

  addAdminUser(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/createAdminUser`, user);
  }

  EditAdminUser(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editAdminUser`, user);
  }
  addRole(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/createRole`, user);
  }

  editRole(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/editRole`, user);
  }

  updateAgentImages(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/updateAgentImage`, user);
  }

  updateZone(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/editZone`, user);
  }

  //Nationality
  getNationality(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getAllNationality`,
      { params: param1 });
  }

  addNationality(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/addNationality`, user);
  }

  editNationality(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editNationality`, user);
  }

  deleteNationality(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/deleteNationality`, user);
  }

  getActiveNationality(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.endpoint}/getActiveNationality`,
      { params: param1 });
  }

  editAgentData(user: any) {

    return this.http
      .post<any>(`${this.endpoint}/editAgentProfile`, user);
  }

  getAgentAssignOrders(object:any): Observable<any> {
    console.log()
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set('agentId', object.agentId)
      .set('startDate', object.startDate)
      .set('endDate', object.endDate)
    return this.http.get<any>(`${this.endpoint}/getAssignedOrderByAgentId`,
      { params: param1 });
  }

  getCancelRequestFromBiker(): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http.get<any>(`${this.newEndpoint}/getCancelRequestFromAgent`,
      { params: param1 });
  }

  cancelAndComplete(user: any) {
    return this.http
      .post<any>(`${this.newEndpoint}/cancelOrder`, { ...user, adminId: this.adminid });
  }

  getUserbyid(id): Observable<any> {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('userId', id)
    return this.http.get<any>(`${this.newEndpoint}/getUserDetailsById`,
      { params: param1 });
  }

  addWalletAdjustment(user: any) {
    return this.http
      .post<any>(`${this.endpoint}/transactionAdjustments`, user);
  }

  getCouponDetails(couponId: any) {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set("couponId", couponId);
    return this.http
      .get<any>(`${this.endpoint}/getCouponDetails`, { params: param1 });
  }

  getSubscriptionDetails(params: any) {
    let param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
      .set("subscriptionId", params?.subscriptionId)
      .set("status", params?.status)
    if (params?.userId) {
      param1 = param1.append('userId', params.userId);
    }
    return this.http
      .get<any>(`${this.endpoint}/subscriptionsDetails`, { params: param1 });
  }
  
  getBanners() {
    const param1 = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http
      .get<any>(`${this.endpoint}/getAllBanners`, { params: param1 });
  }

  saveBannerData(data:any){
    return this.http
    .post<any>(`${this.endpoint}/addUpdateBanner`, data);
  }

  deleteBanner(id){
    return this.http
    .delete(`${this.endpoint}/deleteBanner/${id}`);
  }

  addCorporateCustomer(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/create-corporate-customer`, data);
  }

  getCorporateCustomer() {
    const params = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http
      .get<any>(`${this.endpoint}/get-corporate-customer`,{ params : params });
  }

  editCorporate(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/edit-corporate-customer`, data);
  }

  openGoogleMap(latitude : number , longitude : number) {
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`)
  }

  unAssignedOrderCount() {
    const params = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http
      .get<any>(`${this.endpoint}/getUnAssignedOrderCount`, { params: params });
  }

  addNotes(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/add-notes`, data);
  }

  agentCount(){
    const params = new HttpParams()
      .set('lang', 'en')
      .set('adminId', this.adminid)
    return this.http
      .get<any>(`${this.endpoint}/agent-count`, { params: params });
  }

  deleteAgents(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/delete-agent`, data);
  }

  addZoneDayOff(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/zone-off`, data);
  }

  updateStorage(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/update-storage`, { ...data, adminId: this.adminid });
  }

  storageVoucher(data: any) {
    return this.http
      .post<any>(`${this.endpoint}/storage-voucher`, { ...data, adminId: this.adminid });
  }

  updateStorageVoucher(data) {
    return this.http
      .put<any>(`${this.endpoint}/storage-voucher`, { ...data, adminId: this.adminid });
  }

  getStorageVoucher(filter: any) {
    return this.http
      .get<any>(`${this.endpoint}/storage-voucher`, { params: { adminId: this.adminid, ...filter } });
  }

  addStorageTransfer(data) {
    return this.http
      .post<any>(`${this.endpoint}/storage-transfer`, { ...data, adminId: this.adminid });
  }

  updateStorageTransfer(data) {
    return this.http
      .put<any>(`${this.endpoint}/storage-transfer`, { ...data, adminId: this.adminid });
  }

  getStorageTransfer(){
    return this.http
    .get<any>(`${this.endpoint}/storage-transfer`,{ params : { adminId : this.adminid } });
  }

  deleteStorage(storageId: any) {
    return this.http
      .delete<any>(`${this.endpoint}/delete-storage/${storageId}`, { params: { adminId: this.adminid } });
  }

  deleteStorageVoucher(storageVoucherId: any) {
    return this.http
      .delete<any>(`${this.endpoint}/storage-voucher/${storageVoucherId}`, { params: { adminId: this.adminid } });
  }

  deleteStorageTransfer(storageTransferId: any) {
    return this.http
      .delete<any>(`${this.endpoint}/storage-transfer/${storageTransferId}`, { params: { adminId: this.adminid } });
  }

  storageVoucherItemsReport(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.endpoint}/storage-voucher-report-by-items`, data);
  }

  getCountry(): Observable<any> {
    return this.http.get(`${this.endpoint}/country`);
  }

  addAttachments(attachments: any) {
    return this.http
      .post<any>(`${this.endpoint}/storage-voucher-attachments`, { ...attachments, adminId: this.adminid });
  }

  purchaseReport(filters: any) {
    return this.http
      .post<any>(`${this.endpoint}/purchase-report`, filters);
  }

  transferReport(filters: any) {
    return this.http
      .post<any>(`${this.endpoint}/transfer-report`, filters);
  }

  stockReport(filters: any) {
    return this.http
      .post<any>(`${this.endpoint}/stock-report`, filters);
  }

  bikerDateWiseReport(body: any) {
    return this.http
      .post<any>(`${this.endpoint}/agent-date-wise-report`, body);
  }

  logAdminActions(data: AdminActionsI) {
    return this.http
      .post<any>(`${this.endpoint}/logActions`, { ...data, actionBy: this.adminid });
  }

  auditActionReport(filters) {
    return this.http
      .post<any>(`${this.endpoint}/auditReport`, { adminId: this.adminid , ...filters });
  }

  updateReportsPermission(data) {
    return this.http
      .post<any>(`${this.endpoint}/editDashboardReportsPermission`, { adminId: this.adminid, ...data });
  }

  editUserSubscription(data){
    return this.http
      .post<any>(`${this.endpoint}/updateUserSubscription`, { adminId: this.adminid, ...data });
  }

  
}
