<nav class="sidebar">
    <div class="sidebar-header">

        <!--- Logo -->
        <!-- <a routerLink="/" class="sidebar-brand">
            Ghassl<span>Admin</span>
        </a> -->
        <img src="assets/images/g_logo.svg" style=" width: 65px; margin-left: 25px;" class="sidebar-brand" alt="logo">
        <!--- Toggler -->
        <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
            <span></span>
            <span></span>
            <span></span>
        </div>

    </div>
    <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()">

        <!--- Sidemenu start -->

        <!-- <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
            <ng-container *ngFor="let item of menuItems">
                <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label | translate}}</li>
                <div class="nav-item" *ngIf="item != false">

                    <li class="nav-item" *ngIf="!item.isTitle">

                        <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
                            <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
                            <span class="link-title"> {{ item.label | translate}}</span>
                            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
                        </a>

                        <a class="nav-link nav-link-ref" [routerLink]="item.link" *ngIf="!hasItems(item)"
                            [attr.data-parent]="item.parentId">
                            <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
                            <span class="link-title"> {{ item.label | translate}}</span>
                            <span class="badge badge-{{item.badge.variant}}"
                                *ngIf="item.badge">{{item.badge.text}}</span>
                        </a>

                        <ul class="sidebar-nav sub-menu nav-second-level" *ngIf="hasItems(item)" aria-expanded="false">

                            <li class="nav-item" *ngFor="let subitem of item.subItems"
                                [ngClass]="{'side-nav-item': hasItems(subitem)}">
                                <a class="nav-link side-nav-link-a-ref" *ngIf="hasItems(subitem)"
                                    href="javascript:void(0);">
                                    <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon
                                        *ngIf="subitem.icon"></i>
                                    <span class="link-title"> {{ subitem.label | translate}}</span>
                                    <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
                                </a>

                                <a class="nav-link nav-link-ref" [routerLink]="subitem.link" *ngIf="!hasItems(subitem)"
                                    [attr.data-parent]="subitem.parentId">
                                    <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon
                                        *ngIf="subitem.icon"></i> {{ subitem.label | translate}}
                                </a>

                                <ul class="sidebar-nav sub-menu nav-third-level" *ngIf="hasItems(subitem)"
                                    aria-expanded="false">
                                    <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                                        <a class="nav-link nav-link-ref" [routerLink]="subSubitem.link"
                                            [routerLinkActive]="['active']" [attr.data-parent]="subSubitem.parentId">
                                            <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon
                                                *ngIf="subitem.icon"></i> {{ subSubitem.label | translate}}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </div>
            </ng-container>
        </ul> -->
        <!--- Sidemenu end -->

        <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
            <ng-container *ngFor="let item of menuItems">
                <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li>
                <div class="nav-item" *ngIf="item !== false">
                    <li class="nav-item" *ngIf="!item.isTitle">
                        <a class="nav-link" (click)="toggleMenu(item,$event)">
                            <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
                            <span class="link-title"> {{ item.label }}</span>
                            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon *ngIf="hasItems(item)"></span>
                        </a>
        
                        <ul class="sidebar-nav sub-menu nav-second-level" *ngIf="hasItems(item) && item.expanded">
                            <li class="nav-item" *ngFor="let subitem of item.subItems"
                                [ngClass]="{'side-nav-item': hasItems(subitem)}">
                                <a class="nav-link side-nav-link-a-ref" (click)="toggleMenu(subitem,$event)">
                                    <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon
                                        *ngIf="subitem.icon"></i>
                                    <span class="link-title"> {{ subitem.label }}</span>
                                    <span class="link-arrow" data-feather="chevron-down" appFeatherIcon
                                        *ngIf="hasItems(subitem)"></span>
                                </a>
        
                                <ul class="sidebar-nav sub-menu nav-third-level" *ngIf="hasItems(subitem) && subitem.expanded">
                                    <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                                        <a class="nav-link nav-link-ref" [routerLink]="subSubitem.link"
                                            [routerLinkActive]="['active']">
                                            <i class="link-icon" [attr.data-feather]="subSubitem.icon" appFeatherIcon
                                                *ngIf="subSubitem.icon"></i>
                                            {{ subSubitem.label }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </div>
            </ng-container>
        </ul>
    </div>
</nav>